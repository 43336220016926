import React, { useEffect, useState } from "react";
import { CCard, CCardBody, CCol, CRow, CWidgetStatsB } from "@coreui/react";
import WidgetsDropdown from "../widgets/WidgetsDropdown";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";
import { DatePicker, Modal } from "antd";
const { RangePicker } = DatePicker;

import dayjs from "dayjs";
import { addDays } from "date-fns";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardCompleteDetails,
  getPaymentStatus,
} from "../../redux/slices/dashboardSlice";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

import { DateRangePicker } from "react-date-range"; //test
import LineChart from "../widgets/Chart/LineChart";
import ApexChartLineArea from "../widgets/Chart/ApexChartLineArea";
import ApexChartPie from "../widgets/Chart/ApexChartPie";
import ApexChartBar from "../widgets/Chart/ApexChartBar";

// Date converter
const dateConverter = (date) => {
  const arr = date.toString().split(" ");
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date_str =
    arr[3] + "-" + (months.indexOf(arr[1]) + 1).toString() + "-" + arr[2];
  return date_str;
};

const ToggleButtonDate = (startDate, endDate) => {
  const StartDateArr = startDate.toString().split(" ");
  const EndDateArr = endDate.toString().split(" ");

  return StartDateArr[3] === EndDateArr[3]
    ? `${StartDateArr[2]}  ${StartDateArr[1]} - ${EndDateArr[2]}  ${EndDateArr[1]}  ${StartDateArr[3]}`
    : `${StartDateArr[2]}  ${StartDateArr[1]} ${StartDateArr[3]} - ${EndDateArr[2]}  ${EndDateArr[1]}  ${EndDateArr[3]}`;
};

const Dashboard = () => {
  const dispatch = useDispatch();
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    BarElement
  );

  //Calender Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });

  // date range
  const [dateRange, setDateRange] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection",
    },
  ]);

  const [calenderDate, setCalenderDate] = useState(
    // ToggleButtonDate("Toggle Calender")
    "Toggle Calender"
  );

  // Date range for API
  const [apiDate, setApiDate] = useState({
    from: dateConverter(dateRange[0].startDate),
    to: dateConverter(dateRange[0].endDate),
  });

  const state = useSelector((state) => state.dashboard.data);
  let balanceDataDashboard = state.balanceDataDashboard;
  let totalClaimsStatusDashbaord = state.totalClaimsStatusDashbaord;
  let mainDataDashboard = state.dashboardCompleteDetails;
  let redemptionHistoryDashboard = state.redemptionHistoryDashboard;
  let paymentStatusData = state.getPaymentStatus;
  let referedDataDashboard = state.referedDataDashboard;
  let signupUsersData = state.signUpUserDataDashboard;
  let totalTasksDataDashbaord = state.totalTasksDataDashbaord;

  //
  useEffect(() => {
    dispatch(dashboardCompleteDetails());
    dispatch(getPaymentStatus());
  }, []);

  // const past7Days = [...Array(7).keys()].map(index => {
  //   const date = new Date();
  //   date.setDate(date.getDate() - (index + 1));

  //   return date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
  // });

  const onChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };

  const dateRangeCheck = (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    dispatch(dashboardCompleteDetails(data));
  };
  // Past 7 Days=========================================================================================
  let past7Days = [];
  mainDataDashboard &&
    mainDataDashboard.map((item) => {
      past7Days.push(item.graph_date);
    });

  // claims Data===================================================================================/
  let claims_status = [];
  let claims_count = [];
  totalClaimsStatusDashbaord &&
    totalClaimsStatusDashbaord.map((item) => {
      claims_status.push(item?.status);
      claims_count.push(item?.count);
    });

  // const claims_chart_data = {
  //   labels: claims_status,
  //   datasets: [
  //     {
  //       label: "claims",
  //       data: claims_count,
  //       backgroundColor: [
  //         "rgba(255, 99, 132, 0.2)",
  //         "rgba(54, 162, 235, 0.2)",
  //         "rgba(255, 206, 86, 0.2)",
  //         "rgba(75, 192, 192, 0.2)",
  //         "rgba(153, 102, 255, 0.2)",
  //         "rgba(255, 159, 64, 0.2)",
  //       ],
  //       borderColor: [
  //         "rgba(255, 99, 132, 1)",
  //         "rgba(54, 162, 235, 1)",
  //         "rgba(255, 206, 86, 1)",
  //         "rgba(75, 192, 192, 1)",
  //         "rgba(153, 102, 255, 1)",
  //         "rgba(255, 159, 64, 1)",
  //       ],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // redemption data==============================================================================================
  let redemption_status = [];
  let redemption_count = [];
  redemptionHistoryDashboard &&
    redemptionHistoryDashboard.map((item) => {
      redemption_status.push(item?.status);
      redemption_count.push(item?.count);
    });

  const redemption_chart_data = {
    labels: redemption_status,
    datasets: [
      {
        label: "Redemption",
        data: redemption_count,
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  // total task data==============================================================================

  let total_tasks_points = [];
  let total_tasks = [];
  totalTasksDataDashbaord &&
    totalTasksDataDashbaord.map((item) => {
      total_tasks_points.push(item?.points);
      total_tasks.push(item?.task_short_name);
    });

  // const total_task_data = {
  //   total_tasks,
  //   datasets: [
  //     {
  //       label: "Total points",
  //       data: total_tasks_points,
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //     },
  //   ],
  // };

  const total_task_options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Total Task Data",
      },
    },
  };

  // unique,clicks,orders data ================================================================================

  let unique_user_order_dashboard = [];
  let clicks_data_dashboard = [];
  let orders_data_dashboard = [];
  let total_cashback_Dashboard = [];
  let xAxisDate = [];
  mainDataDashboard &&
    mainDataDashboard.map((item) => {
      xAxisDate.push(item?.graph_date);
      unique_user_order_dashboard.push(item?.unique_orders);
      orders_data_dashboard.push(item?.total_orders);
      total_cashback_Dashboard.push(item?.total_cb);
      clicks_data_dashboard.push(item?.click_count);
      // total_tasks_points[item.task_short_name] = item.points;
      // unique_user_order_dashboard.push(item.unique_orders)
      // clicks_data_dashboard.push(item.click_count)
      // orders_data_dashboard.push(item.total_orders)
      // total_cashback_Dashboard.push(item.total_cb)
    });

  // let complete_graph_detaisl = {
  //   past7Days,
  //   datasets: [
  //     {
  //       label: "Unique User Orders",
  //       data: unique_user_order_dashboard,
  //       borderColor: "rgb(45,127,299)",
  //       backgroundColor: "rgba(45,127,299, 0.5)",
  //       yAxisID: "y",
  //     },
  //     {
  //       label: "Order Details",
  //       data: orders_data_dashboard,
  //       borderColor: "rgb(53, 162, 235)",
  //       backgroundColor: "rgba(53, 162, 235, 0.5)",
  //       yAxisID: "y1",
  //     },
  //     {
  //       label: "Clicks Data Dashboard",
  //       data: clicks_data_dashboard,
  //       borderColor: "rgb(69, 111, 125)",
  //       backgroundColor: "rgba(69, 111, 125, 0.5)",
  //       yAxisID: "y2",
  //     },
  //     {
  //       label: "Total Cashback",
  //       data: total_cashback_Dashboard,
  //       borderColor: "rgb(255, 99, 132)",
  //       backgroundColor: "rgba(255, 99, 132, 0.5)",
  //       yAxisID: "y3",
  //     },
  //   ],
  // };

  // const complete_details_options = {
  //   responsive: true,
  //   interaction: {
  //     mode: "index",
  //     intersect: false,
  //   },
  //   stacked: false,
  //   plugins: {
  //     title: {
  //       display: true,
  //       text: "Hyyzo Dashboard",
  //     },
  //   },
  //   scales: {
  //     y: {
  //       type: "linear",
  //       display: true,
  //       position: "left",
  //     },
  //     y1: {
  //       type: "linear",
  //       display: true,
  //       position: "right",
  //       grid: {
  //         drawOnChartArea: false,
  //       },
  //     },
  //   },
  // };

  // Change date range
  const handleDateRangeChange = (item) => {
    setDateRange([
      {
        startDate: item.selection.startDate,
        endDate: item.selection.endDate,
        key: "selection",
      },
    ]);
    setCalenderDate(
      ToggleButtonDate(item.selection.startDate, item.selection.endDate)
    );
    // setApiDate({
    //   from: dateConverter(item.selection.startDate),
    //   to: dateConverter(item.selection.endDate)
    // });
    let data = {};
    data.start_date = dateConverter(item.selection.startDate);
    data.end_date = dateConverter(item.selection.endDate);
    setIsModalOpen(item.selection.startDate === item.selection.endDate);
    dispatch(dashboardCompleteDetails(data));
  };

  // useEffect(() => {
  //   dispatch(dashboardCompleteDetails());
  // }, [signupUsersData]);

  // Handle screen resize
  const [months, setMonths] = useState(2);
  useEffect(() => {
    const updateMonths = () => {
      setMonths(window.innerWidth < 768 ? 1 : 2);
    };
    updateMonths(); // Set initially
    window.addEventListener("resize", updateMonths);
    return () => window.removeEventListener("resize", updateMonths);
  }, []);

  return (
    <Box>
      {/* <div className="d-flex justify-content-between mb-4"> */}
      {/* <h6>
          Given Data Date Range BETWEEN {past7Days[0]} and{" "}
          {past7Days[past7Days.length - 1]}{" "}
        </h6> */}
      {/* <div>
          <RangePicker style={{ zIndex: 1059 }} onChange={onChange} />
          <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
            Submit
          </button>
        </div> */}
      <Button
        onClick={showModal}
        color="primary"
        variant="contained"
        startIcon={<CalendarMonthIcon />}
        sx={{ marginBottom: "5px" }}
      >
        <strong>{calenderDate}</strong>
      </Button>
      <Modal
        title="Select Date Range"
        open={isModalOpen}
        footer={null}
        onOk={handleOk}
        onCancel={handleCancel}
        width={"62vw"}
      >
        <div className="dateScrollBar" style={{ overflowX: "auto" }}>
          <DateRangePicker
            ranges={dateRange}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={months}
            direction={months === 1 ? "vertical" : "horizontal"}
            onChange={(item) => handleDateRangeChange(item)}
          />
        </div>
      </Modal>

      <WidgetsDropdown
        signupUsersData={signupUsersData}
        paymentStatusData={paymentStatusData}
        balanceDataDashboard={balanceDataDashboard}
      />

      <CCard className="mb-5">
        <CCardBody>
          <div className="graph" style={{ height: "100%", width: "100%" }}>
            {/* <LineChart
              data={{
                datasets: [
                  {
                    label: "Unique User Orders",
                    data: unique_user_order_dashboard,
                    backgroundColor: [
                      "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y1",
                  },
                  {
                    label: "Order Details",
                    data: orders_data_dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y2",
                  },
                  {
                    label: "Clicks Data Dashboard",
                    data: clicks_data_dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      "rgba(255, 206, 86, 0.2)",
                      // "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      "rgba(255, 206, 86, 1)",
                      // "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y3",
                  },
                  {
                    label: "Total Cashback",
                    data: total_cashback_Dashboard,
                    backgroundColor: [
                      // "rgba(255, 99, 132, 0.2)",
                      // "rgba(54, 162, 235, 0.2)",
                      // "rgba(255, 206, 86, 0.2)",
                      "rgba(75, 192, 192, 0.2)",
                      // "rgba(153, 102, 255, 0.2)",
                      // "rgba(255, 159, 64, 0.2)",
                    ],
                    borderColor: [
                      // "rgba(255, 99, 132, 1)",
                      // "rgba(54, 162, 235, 1)",
                      // "rgba(255, 206, 86, 1)",
                      "rgba(75, 192, 192, 1)",
                      // "rgba(153, 102, 255, 1)",
                      // "rgba(255, 159, 64, 1)",
                    ],
                    borderWidth: 2,
                    yAxisID: "y4",
                  },
                ],
              }}
              options={{
                maintainAspectRatio: false,
                scales: {
                  y1: {
                    type: "linear",
                    display: true,
                    position: "left",
                    ticks: {
                      color: "rgba(255, 99, 132, 1)", // Color of y1 axis labels
                    },
                  },
                  y2: {
                    type: "linear",
                    display: true,
                    position: "left",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(54, 162, 235, 1)", // Color of y2 axis labels
                    },
                  },
                  y3: {
                    type: "linear",
                    display: true,
                    position: "right",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(255, 206, 86, 1)", // Color of y3 axis labels
                    },
                  },
                  y4: {
                    type: "linear",
                    display: true,
                    position: "right",

                    grid: {
                      drawOnChartArea: false,
                    },
                    ticks: {
                      color: "rgba(75, 192, 192, 1)", // Color of y4 axis labels
                    },
                  },
                },
                plugins: {
                  legend: {
                    labels: {
                      font: {
                        size: 15,
                      },
                    },
                  },
                },
              }}
            /> */}
            <ApexChartLineArea
              series={[
                {
                  name: "Unique User Orders",
                  data: unique_user_order_dashboard,
                  // data: [2.5, 1, 4, 2.4, 4.2],
                  yaxis: 0,
                },
                {
                  name: "Order Details",
                  data: orders_data_dashboard,
                  // data: [2.5, 1, 4, 2.4, 4.2].map((x) => x * 100).reverse(),
                  yaxis: 1,
                },
                {
                  name: "Clicks Data Dashboard",
                  data: clicks_data_dashboard,
                  // data: [700, 900, 450, 600, 800],
                  yaxis: 2,
                },
                {
                  name: "Total Cashback",
                  data: total_cashback_Dashboard,
                  // data: [700, 900, 450, 600, 800].map((x) => x / 10).reverse(),
                  yaxis: 4,
                },
              ]}
              options={{
                xaxis: {
                  categories: xAxisDate,
                },
                yaxis: [
                  {
                    seriesName: "Unique User Orders",
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#008FFB",
                    },
                    labels: {
                      style: {
                        colors: "#008FFB",
                      },
                      formatter: (value) => value?.toLocaleString("en-IN"), // Indian number formatting
                    },
                    title: {
                      text: "Unique User Orders",
                      style: {
                        color: "#008FFB",
                      },
                    },
                    tooltip: {
                      enabled: true,
                    },
                  },
                  {
                    seriesName: "Order Details",
                    // opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#00E396",
                    },
                    labels: {
                      style: {
                        colors: "#00E396",
                      },
                      formatter: (value) => value?.toLocaleString("en-IN"), // Indian number formatting
                    },
                    title: {
                      text: "Order Details",
                      style: {
                        color: "#00E396",
                      },
                    },
                  },
                  {
                    seriesName: "Clicks Data Dashboard",
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#FEB019",
                    },
                    labels: {
                      style: {
                        colors: "#FEB019",
                      },
                      formatter: (value) => value?.toLocaleString("en-IN"), // Indian number formatting
                    },
                    title: {
                      text: "Clicks Data Dashboard",
                      style: {
                        color: "#FEB019",
                      },
                    },
                  },
                  {
                    seriesName: "Total Cashback",
                    opposite: true,
                    axisTicks: {
                      show: true,
                    },
                    axisBorder: {
                      show: true,
                      color: "#FF4560",
                    },
                    labels: {
                      style: {
                        colors: "#FF4560",
                      },
                      formatter: (value) => value?.toLocaleString("en-IN"), // Indian number formatting
                    },
                    title: {
                      text: "Total Cashback",
                      style: {
                        color: "#FF4560",
                      },
                    },
                  },
                ],
              }}
            />
          </div>
        </CCardBody>
      </CCard>

      {/* <CRow>
        <CCol xs={12} md={3} xl={3}>
          <Pie className="width_75 height_75 m-auto" data={claims_chart_data} />
          <h6 className="text-center mt-3">Claims chart Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <Doughnut
            className="width_75 height_75 m-auto"
            data={redemption_chart_data}
          />
          <h6 className="text-center mt-3">Redemption Chart Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <CWidgetStatsB
            className="mb-3"
            color="success"
            progress={{
              value:
                referedDataDashboard &&
                referedDataDashboard.length &&
                referedDataDashboard[0].referredTo,
            }}
            title="Referred To"
            value={`${
              referedDataDashboard &&
              referedDataDashboard.length &&
              referedDataDashboard[0].referredTo
            }`}
          />
          <h6 className="text-center mt-3">Referral Data</h6>
        </CCol>
        <CCol xs={12} md={3} xl={3}>
          <Bar options={total_task_options} data={total_task_data} />
          <h6 className="text-center mt-3">Toatl Task Data</h6>
        </CCol>
      </CRow> */}
      <Box
        display={"flex"}
        gap={2}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-around"}
          gap={2}
          width={"100%"}
          flexWrap={"wrap"}
        >
          <Box sx={{ order: { xs: 1, lg: 1 } }}>
            <ApexChartPie series={claims_count} labels={claims_status} />
            <Typography textAlign={"center"} variant="h6">
              Claims chart Data
            </Typography>
          </Box>
          <Box
            sx={{
              order: { xs: 3, lg: 2 },
              alignSelf: "flex-end",
            }}
            width={{ xs: "100%", lg: "35%" }}
          >
            <CWidgetStatsB
              style={{ width: "100%" }}
              className="mb-3"
              color="#1E8FB7"
              progress={{
                value:
                  referedDataDashboard &&
                  referedDataDashboard.length &&
                  referedDataDashboard[0]?.referredTo,
              }}
              title="Referred To"
              value={`${
                referedDataDashboard &&
                referedDataDashboard.length &&
                referedDataDashboard[0].referredTo?.toLocaleString("en-IN")
              }`}
            />
            <Typography textAlign={"center"} variant="h6">
              Referral Data
            </Typography>
          </Box>
          <Box sx={{ order: { xs: 2, lg: 3 } }}>
            <ApexChartPie
              series={redemption_count}
              labels={redemption_status}
            />
            <Typography textAlign={"center"} variant="h6">
              Redemption Chart Data
            </Typography>
          </Box>
        </Stack>

        {total_tasks_points.length > 0 && total_tasks_points.length > 0 && (
          <Box width={"100%"}>
            <ApexChartBar data={total_tasks_points} categories={total_tasks} />
            <Typography textAlign={"center"} variant="h6">
              Total Task Data
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
