import React from "react";
import { CRow, CCol, CWidgetStatsA, CWidgetStatsF } from "@coreui/react";
// import { getStyle } from '@coreui/utils'
// import { CChartLine } from '@coreui/react-chartjs'
import LineChart from "./Chart/LineChart";
import {
  dashboardCompleteDetails,
  getPaymentStatus,
} from "../../redux/slices/dashboardSlice";
import { useSelector } from "react-redux";
import { Box, Paper, Stack, Typography } from "@mui/material";
import ApexChartLine from "./Chart/ApexChartLine";

const WidgetsDropdown = () => {
  const state = useSelector((state) => state.dashboard.data);
  // totalSignupUsers=============================================
  let totalSignupUsers = 0;
  state.signUpUserDataDashboard &&
    state.signUpUserDataDashboard.forEach((element) => {
      totalSignupUsers += element?.uid;
    });

  let signup_user_uid = [];
  let signup_date = [];
  state.signUpUserDataDashboard &&
    state.signUpUserDataDashboard.map((item) => {
      signup_user_uid.push(item?.uid);
      signup_date.push(item?.c_date);
    });
  return (
    <Box>
      <CRow>
        <CCol style={{ width: "100%", objectFit: "contain" }}>
          <CWidgetStatsA
            className="mb-5 font-weight-bold"
            // color="danger"
            value={<> {totalSignupUsers?.toLocaleString("en-IN")} </>}
            title="Total New Users"
            style={{ width: "100%", border: "2px solid #D3D3D3" }}
            chart={
              // <LineChart
              //   data={{
              //     labels: signup_date,
              //     datasets: [
              //       {
              //         label: "Users",
              //         data: signup_user_uid,
              //         backgroundColor: [
              //           "rgba(255, 99, 132, 0.2)",
              //           "rgba(54, 162, 235, 0.2)",
              //           "rgba(255, 206, 86, 0.2)",
              //           "rgba(75, 192, 192, 0.2)",
              //           "rgba(153, 102, 255, 0.2)",
              //           "rgba(255, 159, 64, 0.2)",
              //         ],
              //         borderColor: [
              //           "rgba(255, 99, 132, 1)",
              //           "rgba(54, 162, 235, 1)",
              //           "rgba(255, 206, 86, 1)",
              //           "rgba(75, 192, 192, 1)",
              //           "rgba(153, 102, 255, 1)",
              //           "rgba(255, 159, 64, 1)",
              //         ],
              //         borderWidth: 1,
              //       },
              //     ],
              //   }}
              //   options={{
              //     maintainAspectRatio: false,
              //     plugins: {
              //       legend: {
              //         labels: {
              //           font: {
              //             size: 15,
              //           },
              //         },
              //       },
              //     },
              //   }}
              // />
              <ApexChartLine
                chartXAxis={signup_date}
                chartData={[
                  {
                    name: "Users",
                    data: signup_user_uid,
                  },
                ]}
              />
            }
          />
        </CCol>
      </CRow>
      {/* <CRow>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4 pb-4 font-weight-bold"
            color="primary"
            value={<> </>}
            title="Total User Balance"
            chart={
              <>
                <CWidgetStatsF
                  className="mb-3"
                  color="primary"
                  padding={false}
                  title="Pending Balance"
                  value={
                    state.balanceDataDashboard &&
                    state.balanceDataDashboard.length &&
                    state.balanceDataDashboard[0].pending
                  }
                />
                <CWidgetStatsF
                  className="mb-3"
                  color="primary"
                  padding={false}
                  title="Approved Balance"
                  value={
                    state.balanceDataDashboard &&
                    state.balanceDataDashboard.length &&
                    state.balanceDataDashboard[0].approved
                  }
                />
                <CWidgetStatsF
                  color="primary"
                  padding={false}
                  title="Redeemed Balance"
                  value={
                    state.balanceDataDashboard &&
                    state.balanceDataDashboard.length &&
                    state.balanceDataDashboard[0].redeemed
                  }
                />
              </>
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="mb-4 pb-4 font-weight-bold"
            color="warning"
            value={<> </>}
            title="Total Payment Status"
            chart={
              <>
                <CWidgetStatsF
                  className="mb-3"
                  style={{ fontSize: "20px" }}
                  // color="primary"
                  padding={false}
                  title="payment status"
                  value={
                    state.getPaymentStatus && state.getPaymentStatus.length
                      ? state.getPaymentStatus.length
                      : 0
                  }
                />
              </>
            }
          />
        </CCol>
      </CRow> */}
      <Stack
        marginBottom={2}
        padding={2}
        border={"2px solid #D3D3D3"}
        borderRadius={2}
        width={"100%"}
        direction={"row"}
        justifyContent={"space-between"}
        color={"white"}
      >
        <Box p={2} width={"100%"}>
          <Stack
            gap={3}
            alignItems={"center"}
            justifyContent={"space-evenly"}
            flexWrap={"wrap"}
            direction={"row"}
          >
            <Paper
              elevation={10}
              sx={{
                width: "230px",
                height: "150px",
                p: "10px",
                backgroundColor: "#FF6384",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" color="inherit">
                Pending Balance
              </Typography>
              <Typography variant="h3" textAlign={"center"} color="inherit">
                {state.balanceDataDashboard && state.balanceDataDashboard.length
                  ? state.balanceDataDashboard[0]?.pending?.toLocaleString(
                      "en-IN"
                    )
                  : 0}
              </Typography>
              <Typography textAlign={"center"} variant="body2" color="inherit">
                vs previous 30 Days
              </Typography>
            </Paper>
            <Paper
              elevation={10}
              sx={{
                height: "150px",
                width: "230px",
                p: "10px",
                backgroundColor: "#36A2EB",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" color="inherit">
                Approved Balance
              </Typography>
              <Typography variant="h3" textAlign={"center"} color="inherit">
                {state.balanceDataDashboard && state.balanceDataDashboard.length
                  ? state.balanceDataDashboard[0]?.approved?.toLocaleString(
                      "en-IN"
                    )
                  : 0}
              </Typography>
              <Typography textAlign={"center"} variant="body2" color="inherit">
                vs previous 30 Days
              </Typography>
            </Paper>
            <Paper
              elevation={10}
              sx={{
                height: "150px",
                width: "230px",
                p: "10px",
                backgroundColor: "#FFCE56",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" color="inherit">
                Redeemed Balance
              </Typography>
              <Typography variant="h3" textAlign={"center"} color="inherit">
                {state.balanceDataDashboard && state.balanceDataDashboard.length
                  ? state.balanceDataDashboard[0]?.redeemed?.toLocaleString(
                      "en-IN"
                    )
                  : 0}
              </Typography>
              <Typography textAlign={"center"} variant="body2" color="inherit">
                vs previous 30 Days
              </Typography>
            </Paper>

            <Paper
              elevation={10}
              sx={{
                height: "150px",
                width: "230px",
                p: "10px",
                backgroundColor: "#4BC0C0",
                color: "#ffffff",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body1" color="inherit">
                Payment status
              </Typography>
              <Typography variant="h3" textAlign={"center"} color="inherit">
                {state.getPaymentStatus && state.getPaymentStatus.length
                  ? state.getPaymentStatus?.length?.toLocaleString("en-IN")
                  : 0}
              </Typography>
              <Typography textAlign={"center"} variant="body2" color="inherit">
                vs previous 30 Days
              </Typography>
            </Paper>
          </Stack>
        </Box>
        {/* <Box p={2} width={"25%"}>
          
          
        </Box> */}
      </Stack>
    </Box>
  );
};

export default WidgetsDropdown;
