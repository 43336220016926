import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartBar = ({ data, categories }) => {

  // console.log("Apex Chart Bar ",data,categories);
  const series = [{ data: data, name: "Total Task Data" }];
  const options = {
    chart: {
      type: "bar",
      height: 240,
    },

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        borderRadius: 5,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: (value) => value?.toLocaleString("en-IN"), // Indian format
      },
      title: {
        text: "Total Task Data",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: (val) => val?.toLocaleString("en-IN") + " tasks",
      },
    },
  };
  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={240}
      />
    </div>
  );
};

export default ApexChartBar;
