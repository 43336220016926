import ApexCharts from "react-apexcharts";
import React from "react";

const ApexChartLine = ({ chartXAxis, chartData }) => {
  // console.log("Apex Chart Line ", chartXAxis, chartData);
  const chartOptions = {
    chart: {
      id: "area-chart",
      height: 400,
      type: "area",
      animations: {
        enabled: true,
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    noData: {
      text: "📅 No Data Available for the Selected Date Range",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#555", // Slightly darker for better contrast
        fontSize: "20px", // Larger font for readability
        fontWeight: "bold", // Makes the message stand out
        fontFamily: "Arial, sans-serif", // Clean and modern font
        background: "rgba(0, 0, 0, 0.05)", // Light background for subtle emphasis
        padding: "10px", // Adds spacing for better appearance
        borderRadius: "8px", // Smooth rounded edges
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: chartXAxis,
    },
    yaxis: {
      labels: {
        formatter: (value) => value?.toLocaleString("en-IN"), // Indian number formatting
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 100, 100, 100],
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (value) => value?.toLocaleString("en-IN"), // Format tooltip values
      },
    },

    legend: {
      show: true,
      position: "top",
    },
  };

  // Sample data for the graph
  // const chartData = [
  //   {
  //     name: "Sales",
  //     data: [30, 40, 45, 50, 49, 60, 70, 91, 125, 150, 160, 200],
  //   },
  // ];
  return (
    <div>
      <ApexCharts
        options={chartOptions}
        series={chartData}
        type="area"
        height={400}
      />
    </div>
  );
};

export default ApexChartLine;
