import React, { Fragment, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import {
    Input, message, Select
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { manualBonusPointsRecords } from "../../../redux/slices/manualBonusPointsSlice"
const { Option } = Select;
import MUIDataTable from "mui-datatables";
import CircularProgress from '@mui/material/CircularProgress';

const { TextArea } = Input;

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const Data = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  > label {
    width: 30%;
  }
  > span {
    font-size: 18px;
    font-weight: bolder;
  }
  > input[type="text"] {
    width: 50%;
  }
  @media screen and (max-width: 480px) {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmitButton = styled.div`
  width: 140px;
  height: 40px;
  display: flex;
  background: #0000ff;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;
  > p {
    font-size: 16px;
    font-weight: bolder;
    margin-bottom: 0px;
    color: #fff;
    text-align: center;
  }
`;
const DataRow = styled.div`
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
`;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-weight: bold;
    font-size: 14px;
    margin-right: 30px;
  }
`;

const ManualPointsRecord = () => {
    const dispatch = useDispatch();
    const { userNameData, advancePayment } = useSelector(s => s.manualBonus)
    const [allValues, setAllValues] = useState({
        user_id: "",
        addPoints: "",
        subPoints: "",
    });
    const [loader, setLoader] = useState(false)

    const changeHandler = (e) => {
        setAllValues({ ...allValues, [e.target.name]: e.target.value });
        // console.log(e);
    };

    useEffect(() => {
        dispatch(manualBonusPointsRecords());
    }, []);

    const submitHandler = async (e, type) => {
        e.preventDefault();
        setLoader(true)
        let data = {};
        data.user_id = allValues.user_id;
        data.points = allValues.addPoints ? allValues.addPoints : -allValues.subPoints;
        data.type = type
        dispatch(manualBonusPointsRecords(data))
            .then(() => {
                setLoader(false)
                if (type != "userDetails") {
                    setAllValues({
                        user_id: "",
                        points: "",
                        initial_status: "",
                        remark: "",
                    })
                    dispatch(manualBonusPointsRecords())
                }
                message.success(type == "userDetails" ? "Fetched user details successfully" : "Added bonus points successfully.")
            })
            .catch(() => {
                message.error(type == "userDetails" ? "Error fetching user details" : "Error adding bonus points.")
            })
    };

    const columns = [
        {
            name: "uid",
            label: "UID",
        },
        {
            name: "name",
            label: "Posted By",
        },
        {
            name: "advance_point",
            label: "Points",
        },
        {
            name: "insert_time",
            label: "Posted Time",
        },
    ];

    const options = {
        selectableRows: 'none',
        rowsPerPage: 100,
        rowsPerPageOptions: [100],
        pagination: false,
        serverSide: true,
        search: true,
        viewColumns: true,
        enableNestedDataAccess: ".",
    }

    return (
        <>
            <Container className="mt-5">
                <form>
                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <DataRow>
                                <label for="" className="form-label" style={{ width: "40%" }}>
                                    Enter User ID
                                </label>
                                <input
                                    type="text"
                                    className="form-control w-75"
                                    name="user_id"
                                    onChange={changeHandler}
                                    required={require}
                                    value={allValues.user_id}
                                />
                            </DataRow>
                            <h3>{userNameData[0] && userNameData[0].name}</h3>
                            {
                                !userNameData[0] && !userNameData[0]?.name && !loader &&
                                <div className="col-12 col-lg-12 my-3 text-center ">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => submitHandler(e, "userDetails")}
                                    >
                                        Submit
                                    </button>
                                </div>
                            }

                            {userNameData[0] && userNameData[0].name &&
                                <>
                                    <DataRow>
                                        <label for="" className="form-label" style={{ width: "40%" }}>
                                            Enter Amount to add
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control w-75"
                                            name="addPoints"
                                            onChange={changeHandler}
                                            placeholder="Enter Amount to add"
                                            required={require}
                                            value={allValues.addPoints}
                                            disabled={allValues.subPoints}
                                        />
                                    </DataRow>

                                    <DataRow>
                                        <label for="" className="form-label" style={{ width: "40%" }}>
                                            Enter Amount to subtract
                                        </label>
                                        <input
                                            type="number"
                                            className="form-control w-75"
                                            name="subPoints"
                                            onChange={changeHandler}
                                            placeholder="Enter Amount to subtract"
                                            required={require}
                                            value={allValues.subPoints}
                                            disabled={allValues.addPoints}
                                        />
                                    </DataRow>
                                    <div className="col-12 col-lg-12 my-3 text-center ">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                            onClick={(e) => submitHandler(e, "formDetails")}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </>
                            }
                            {
                                loader && <CircularProgress />
                            }

                        </div>

                    </div>
                </form>
            </Container>
            <MUIDataTable
                title={"Bonus Points"}
                data={advancePayment}
                columns={columns}
                options={options}
            />
        </>
    );
};

export default ManualPointsRecord;
