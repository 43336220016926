import React from "react";
import ReactApexChart from "react-apexcharts";

const ApexChartPie = ({ series, labels }) => {
  // const series= [44, 55, 13, 43, 22]
  // console.log("Apex Chart Pie ", series, labels);
  const options = {
    chart: {
      width: 300,
      type: "pie",
    },
    noData: {
      text: "📅 No Data Available ",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#666",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
    labels: labels,
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -12,
        },
      },
    },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      formatter: (val, { seriesIndex }) =>
        `${labels[seriesIndex]}: ${series[seriesIndex].toLocaleString(
          "en-IN"
        )}`,
    },
    tooltip: {
      y: {
        formatter: (val) => val?.toLocaleString("en-IN"), // Indian format
      },
    },
    legend: {
      show: true,
      position: "bottom",
      floating: true,
      horizontalAlign: "center",
      verticalAlign: "bottom",
      offsetX: 210,
      offsetY: 22,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };
 
  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="pie"
        width={300}
      />
    </div>
  );
};

export default ApexChartPie;
