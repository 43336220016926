import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import styled from "styled-components";
import { Radio, DatePicker, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  searchByClickId,
  SearchByClickIdByDate,
} from "../../../redux/slices/crmSlice";
import { CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;
class TableRow extends React.Component {
  render() {
    return (
      <tr>
        <td style={{ padding: "8px 10px" }}>{this.props.row[0]}</td>
        <td style={{ padding: "8px 10px" }}>{this.props.row[1]}</td>
        {/* <td>{this.props.row[0]}</td> */}
      </tr>
    );
  }
}

const { RangePicker } = DatePicker;
const SearchByClickId = () => {
  const {
    userInfo,
    userBalanceInfo,
    clicksDataInfo,
    redemption_info,
    orderDetailsInfo,
    requestedBalanceInfo,
    redemptionPaymentInfo,
    userRedemptionInfo,
    redemptiondBalanceInfo,
    clicksByDate,
    cancelledAmount,
    referredCount,
    advancePayment,
    referredPoints
  } = useSelector((state) => state.crm.data);
  const state = useSelector((s) => s);
  const dispatch = useDispatch();
  const [clicks, setClicks] = useState("");
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [menu_type, setMenuType] = useState("click_id");
  const [loader, setLoader] = useState(false)
  const [referredPointsSum, setReferredPointsSum] = useState(0)
  const [searchParams] = useSearchParams();

  const handleRadio = (e) => {
    setMenuType(e.target.value);
  };


  useEffect(() => {
    debugger
    const params = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    if (Object.keys(params).length) {
      for (const [key, value] of Object.entries(params)) {
        setMenuType(key)
        setClicks(value)
        searchByURL(key, value)
      }
    }
  }, [])

  useEffect(() => {
    setReferredPointsSum(referredPoints.reduce((sum, item) => sum + (item.referred_points || 0), 0))
  }, [referredPoints])

  const columns1 = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "uid",
      label: "User_id",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "name",
      label: "User Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "mobile",
      label: "User Mobile",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "points",
      label: "points",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "details",
      label: "details",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "type",
      label: "type",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "method",
      label: "method",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "remarks",
      label: "remarks",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created_at",
      label: "created_at",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "updated_at",
      label: "updated_at",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];
  const options1 = {
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    fixedHeader: false,
    fixedSelectColumn: false,
    rowHover: false,
  };

  const columns2 = [
    {
      name: "pending",
      label: "Pending",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "approved",
      label: "approved",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "redeemed",
      label: "redeemed",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options2 = {
    filterType: "pending",
  };

  // column3  start=====================================================

  const columns3 = [
    // {
    //     name: "store_id",
    //     label: "Store_id",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },

    {
      name: "ts_name",
      label: "Store/task Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //     name: "task_id",
    //     label: "Task Id",
    //     options: {
    //         filter: false,
    //         sort: false,
    //     }
    // },
    // {
    //     name: "task_short_name",
    //     label: "Task Short Name",
    //     options: {
    //         filter: false,
    //         sort: false,
    //     }
    // },
    {
      name: "click_id",
      label: "Click Id",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "partner",
      label: "Partner Name",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "master_activity",
      label: "master activity",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "date",
      label: "Clicks Date",
      options: {
        filter: true,
        sort: false,
      },
    },
  ];

  const options3 = {
    filterType: "click_id",
  };

  // column 4 start============================================================
  const columns4 = [
    // {
    //     name: "store_id",
    //     label: "Store_id",
    //     options: {
    //         filter: true,
    //         sort: false,
    //     }
    // },

    {
      name: "ts_name",
      label: "Store/Task Name",
      options: {
        filter: false,
        sort: false,
      },
    },
    // {
    //     name: "task_id",
    //     label: "Task Id",
    //     options: {
    //         filter: false,
    //         sort: false,
    //     }
    // },
    // {
    //     name: "task_short_name",
    //     label: "Task Short Name",
    //     options: {
    //         filter: false,
    //         sort: false,
    //     }
    // },

    {
      name: "status",
      label: "status",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "partner_name",
      label: "Partner Name",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "click_id",
      label: "Click Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "order_id",
      label: "Order Id",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "sale_amount",
      label: "Sale Amount",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "points",
      label: "Points",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "master_activity",
      label: "Master Activity",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "posting_date",
      label: "Posting Date",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "est_approval_date",
      label: "Estimated Approval Date",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const options4 = {
    filterType: "store_name",
  };

  // column 5 start================================

  const columns5 = [
    {
      name: "details",
      label: "details",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "type",
      label: "type",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "method",
      label: "method",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "points",
      label: "points",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "activity_wise_points",
      label: "activity_wise_points",
      options: {
        filter: false,
        sort: false,
      },
    },

    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "reamrks",
      label: "Remarks",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];
  const columns6 = [
    {
      name: "referredTo",
      label: "Referred To",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "name",
      label: "Name of referred person",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "points",
      label: "Total Earnings",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "referred_points",
      label: "Total Referred Points",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "latest_posting_date",
      label: "Latest Posting Date",
      options: {
        filter: false,
        sort: false,
      },
    }
  ];

  const option5 = {
    filterType: "method",
  };

  const handleClicksByDate = (e) => {
    e.preventDefault();
    let data = {};
    data.start_date = selectedDate.start_date;
    data.end_date = selectedDate.end_date;
    data.uid = userInfo[0].uid;
    dispatch(SearchByClickIdByDate(data));
  };
  const onChange = (date, dateString) => {
    // console.log(dateString, "dateString", date);
    setSelectedDate({ start_date: dateString[0], end_date: dateString[1] });
    // setSelectedDate(dateString);
    // console.log(dateString);
  };

  const searchClickId = (e) => {
    setLoader(true)
    e.preventDefault();
    let data = {};
    if (menu_type == "click_id") data.click_id = clicks;
    if (menu_type == "mobile") data.mobile = clicks;
    if (menu_type == "email") data.email = clicks;
    if (menu_type == "userId") data.userId = clicks;
    dispatch(searchByClickId(data))
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
      })
  };

  const searchByURL = (type, value) => {
    setLoader(true)
    let data = {};
    if (type == "click_id") data.click_id = value;
    if (type == "mobile") data.mobile = value;
    if (type == "email") data.email = value;
    if (type == "userId") data.userId = value;
    dispatch(searchByClickId(data))
      .then(() => {
        setLoader(false)
      })
      .catch(() => {
        setLoader(false)
      })
  };

  const changeClicks = (e) => {
    setClicks(e.target.value);
  };
  const transp = (e) => {
    let keys = Object.entries(e[0]);
    let table = [];
    keys.forEach((e1) => {
      table.push({ name: e1[0], value: e1[1] });
      // table += '<tr><td>' + e1[0] + '</td><td>' + e1[1] + '</td></tr>';
    });
    return table;
  };
  const transp1 = (e) => {
    return Object.entries(e[0]);
  };
  const transp2 = (e) => {
    return Object.entries(e[0]);
  };
  return (
    <Fragment>
      <form action="#" onSubmit={searchClickId}>
        <RadioContainer>
          <div>
            <Radio
              type="radio"
              value={"click_id"}
              // name="type"
              checked={menu_type == "click_id"}
              onChange={handleRadio}
            />
            Search By Click Id
          </div>
          <div>
            <Radio
              type="radio"
              value={"mobile"}
              // name="type"
              checked={menu_type == "mobile"}
              onChange={handleRadio}
            />
            Search By Mobile
          </div>
          <div>
            <Radio
              type="radio"
              value={"email"}
              // name="type"
              checked={menu_type == "email"}
              onChange={handleRadio}
            />
            Search By Email
          </div>
          <div>
            <Radio
              type="radio"
              value={"userId"}
              // name="type"
              checked={menu_type == "userId"}
              onChange={handleRadio}
            />
            Search By User Id
          </div>
        </RadioContainer>
        <input
          className="m-3 px-3 py-2"
          type="text"
          name="click_id"
          value={clicks}
          onChange={changeClicks}
        />
        <input type="submit" disabled={loader} />
      </form>
      {
        loader ?
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            height: '100%',
            widht: '100%'
          }}>
            <CircularProgress />
          </div>
          :
          <>
            <div className="d-flex w-100">
              <table className="col-7">
                <thead>
                  <tr>
                    {/* {userInfo && transp(userInfo).map(head => <th>{head}</th>)} */}
                  </tr>
                </thead>
                <tbody>
                  {userInfo.length > 0 &&
                    transp1(userInfo).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))}
                  {referredCount.length > 0 &&
                    transp2(referredCount).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))}
                  {/* {body.map(row => <TableRow row={row} />)} */}
                </tbody>
              </table>

              <table className="col-4" style={{ marginLeft: "50px" }}>
                <thead>
                  <tr>
                    {/* {userInfo && transp(userInfo).map(head => <th>{head}</th>)} */}
                  </tr>
                </thead>
                <tbody>
                  {
                    advancePayment.length > 0 &&
                    transp2(advancePayment).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))
                  }
                  {
                    referredPointsSum > 0 &&
                    <TableRow row={['Referred Points', referredPointsSum]} />
                  }

                  {userBalanceInfo.length > 0 &&
                    transp2(userBalanceInfo).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))}
                  {requestedBalanceInfo.length > 0 &&
                    transp2(requestedBalanceInfo).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))}
                  {cancelledAmount.length > 0 &&
                    transp2(cancelledAmount).map((row, index) => (
                      <TableRow key={index} row={row} />
                    ))}
                  {/* {body.map(row => <TableRow row={row} />)} */}
                </tbody>
              </table>
            </div>
            <MUIDataTable
              title={"USER Order_details INFORMATION"}
              className="mt-4"
              data={orderDetailsInfo && orderDetailsInfo}
              columns={columns4}
              options={options4}
            />
            <MUIDataTable
              title={"Referral Point Post"}
              className="mt-4"
              data={referredPoints && referredPoints}
              columns={columns6}
              options={options4}
            />
            {clicksByDate.length > 0 && (
              <MUIDataTable
                title={
                  <form onSubmit={handleClicksByDate}>
                    <div className="d-flex justify-content-between">
                      <h4 className="text-center"> User clicks Data</h4>
                      <div>
                        <RangePicker onChange={onChange} />
                        <input className="ml-3" type="submit" />
                      </div>
                    </div>
                  </form>
                }
                className="mt-4"
                data={clicksByDate && clicksByDate}
                columns={columns3}
                options={options3}
              />
            )}
            {clicksDataInfo && (
              <MUIDataTable
                title={
                  <form onSubmit={handleClicksByDate}>
                    <div className="d-flex justify-content-between">
                      <h4 className="text-center"> User clicks Data</h4>
                      <div>
                        <RangePicker onChange={onChange} />
                        <input className="ml-3" type="submit" />
                      </div>
                    </div>
                  </form>
                }
                className="mt-4"
                data={clicksDataInfo && clicksDataInfo}
                columns={columns3}
                options={options3}
              />
            )}
            <MUIDataTable
              title={"User Redemption Information"}
              className="mt-4"
              data={userRedemptionInfo && userRedemptionInfo}
              columns={columns5}
              options={option5}
            />
            <MUIDataTable
              title={"User Redemption Payment Information"}
              className="mt-4"
              data={redemptiondBalanceInfo && redemptiondBalanceInfo}
              columns={columns1}
              options={option5}
            />
          </>
      }
    </Fragment>
  );
};
export default SearchByClickId;
