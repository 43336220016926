import React, { Fragment, useState, useEffect, useCallback } from "react";
import MUIDataTable from "mui-datatables";
import { Popconfirm, Typography, message, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import AppConstants from "../../../constants/appConstants";
import { useDispatch, useSelector } from "react-redux";
import {
  gotActivitiesDataRow,
  showActivitiesData,
  activatedTasksData,
} from "../../../redux/slices/activitiesSlice";
import Tooltip from '@mui/material/Tooltip';
const ShowActivities = () => {
  const dispatch = useDispatch();

  const activityData = useSelector(
    (state) => state.activities.data.activityData
  );
  const [isDeleted, setIsDeleted] = useState(false);
  // let history = useHistory(); //useHistory is being deprecated in react-router v6 or above
  const navigate = useNavigate();

  const edit = (e, value) => {
    let data = {};
    // console.log(value, "value-------------");
    // data.partner_id = value[2]
    data.task_id = value[2];
    data.task_title = value[3];
    data.task_url = value[4];
    data.task_short_name = value[5];
    data.task_description = value[6];
    data.url = value[7];
    data.link = value[8];
    data.approval_date = value[9];
    data.after_days = value[10];
    data.after_month = value[11];
    data.exact_day = value[12];
    data.html = value[13];
    data.imageId = value[14];
    data.partner_id = value[15];
    data.partner_name = value[16];
    data.priority = value[17];
    data.d_type = "html";
    data.payout = value[20]
    dispatch(gotActivitiesDataRow(data));
    navigate("/activities/addnewactivity");
  };
  const cancel = () => {
    //console.log("Cancelled");
  };

  const callbackFunc = () => {
    dispatch(showActivitiesData());
  };

  const activated = (e, value) => {
    let data = {};
    data.task_id = value[2];
    data.type = "activate";
    let query = queryString.stringify(data);
    dispatch(activatedTasksData(query));
    message.success("Task data Activated Successfully");
  };

  const deactivated = (e, value) => {
    let data = {};
    data.task_id = value[2];
    data.type = "deactivate";
    let query = queryString.stringify(data);
    dispatch(activatedTasksData(query));
    message.success("Task data Deactivated Successfully");
  };

  // const remove = useCallback((e) => {
  //   let data = {};
  //   data.active = 0;
  //   props.removeActivityData(data);
  //   //   const newArr = [...finalArray];
  //   //   newArr.splice(newArr.findIndex(item => item.name === e.target.name), 1)
  //   //   setFinalArray(newArr)
  //   // }, [finalArray]);
  // })

  useEffect(() => {
    dispatch(showActivitiesData());
  }, []);

  const columns = [
    // {
    //   name: "Action",
    //   label: "Action",
    //   options: {
    //     filter: false,
    //     customBodyRender: (value, tableMeta, updateValue) => (
    //       <div>
    //         <Popconfirm
    //           title="Are you sure delete this task?"
    //           onConfirm={e => remove(e, tableMeta.rowData)}
    //           onCancel={cancel}
    //           okText="Yes"
    //           cancelText="No"
    //         >
    //           <a href="#">Deactivate</a>
    //         </Popconfirm>
    //       </div>
    //     )
    //   }
    // },

    {
      name: "Action",
      label: "Action",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: "flex" }}>
            <Popconfirm
              title="activate the activity"
              onConfirm={(e) => activated(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a
                href="#"
                style={{
                  padding: 5,
                  fontWeight: "bolder",
                  color: "#078101",
                  border: "1px solid #aaa",
                  borderRadius: "5px",
                  boxShadow: "2px 2px 0px 0px",
                  cursor: "pointer",
                  marginBottom: "10px",
                }}
              >
                Activate
              </a>
            </Popconfirm>
            <Divider type="vertical"></Divider>
            <Popconfirm
              title="Deactivated"
              onConfirm={(e) => deactivated(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a
                href="#"
                style={{
                  padding: 5,
                  fontWeight: "bolder",
                  color: "#FF1502",
                  border: "1px solid #aaa",
                  borderRadius: "5px",
                  boxShadow: "2px 2px 0px 0px",
                  cursor: "pointer",
                }}
              >
                Deactivate
              </a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "opertaion",
      label: "Operation",
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            <Popconfirm
              title="Edit this task?"
              onConfirm={(e) => edit(e, tableMeta.rowData)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Edit</a>
            </Popconfirm>
          </div>
        ),
      },
    },
    {
      name: "task_id",
      label: "Task ID",
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: "task_title",
      label: "Task Title",
      options: {
        customBodyRender: (desc) => {
          return (
            <Tooltip title={desc}>
              <p
                style={{
                  margin: 0,
                  width: "200px"
                }}
              >
                {desc}
              </p>
            </Tooltip>
          );
        },
        filter: false,
        sort: false,
      },
    },
    {
      name: "task_url",
      label: "Task Url",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "task_short_name",
      label: "Task Short Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "task_description",
      label: "Task Description",
      options: {
        customBodyRender: (desc) => {
          return (
            <Tooltip title={desc}>
              <p
                style={{
                  margin: 0,
                  width: "300px",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
                }}
              >
                {desc}
              </p>
            </Tooltip>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "url",
      label: "Image",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {" "}
            <img src={value} alt="image" style={{ width: "100px" }} />
            <p>{console.log(value, "value")}</p>
          </>
        ),
      },
    },
    {
      name: "link",
      label: "LINK",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "approval_date",
      label: "Approval Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "after_days",
      label: "After Days",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "after_month",
      label: "After Months",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "exact_day",
      label: "Exact Day",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "html",
      label: "HTML",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <div
            style={{
              margin: 0,
              width: "300px",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "pre-wrap" // This ensures that line breaks in the description are respected
            }}
            dangerouslySetInnerHTML={{
              __html: value,
            }}
          ></div>
        ),
      },
    },
    {
      name: "image",
      label: "Image Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "partner_id",
      label: "Partner_id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "partner",
      label: "Partner Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "priority",
      label: "Priority",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "active",
      label: "Active",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "created",
      label: "Created",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "cashback_upto",
      label: "Payout",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    filterType: "task_short_name",
  };
  return (
    <Fragment>
      <MUIDataTable
        title={"Show Description (search by task-short name)"}
        data={activityData && activityData}
        columns={columns}
        options={options}
      />
    </Fragment>
  );
};
export default ShowActivities;
