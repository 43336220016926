import React from "react";
import ReactApexChart from "react-apexcharts";
const ApexChartLineArea = ({series,options}) => {
    // console.log("Apex Chart Line Area ", series);

  const chartOptions = {
    chart: {
      type: "area",
      height: 450,
      animations: {
        enabled: true,
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    
    dataLabels: {
      enabled: false,
    },
    stroke: {
      // show: true,
      // width: 2,
      // colors: [""],
      curve: "smooth",
      width:1.5,
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 100, 100, 100],
      },
    },

    ...options,
  };

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={series}
        type="area"
        height={450}
      />
    </div>
  );
};

export default ApexChartLineArea;
