import React, { Component, useEffect, useRef, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Layout, Menu, Breadcrumb, Dropdown, message } from "antd";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  UserOutlined,
  LogoutOutlined,
  DashboardOutlined,
  CarryOutOutlined,
  FileAddOutlined,
  BellFilled,
} from "@ant-design/icons";
import { Badge } from "antd";
import styled, { createGlobalStyle } from "styled-components";
import { Link, Navigate } from "react-router-dom";
import "../index.css";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { createTheme } from "@mui/material/styles";

import {
  getStoreData,
  modulesByUserDataPanel,
  getCountForLayout,
} from "../redux/slices/layoutSlice";
// import { dispatch } from "rxjs/internal/observable/pairs";
import { useDispatch } from "react-redux";

import { getProfile } from "../redux/slices/authSlice";

import { useAuth } from "../context/authContext";
import { Box } from "@mui/material";

// import { useNavigate } from "react-router-dom";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const Logo = styled.div`
  text-align: center;

  > p {
    margin: 0;
  }
  > p > b {
    color: #ff5733;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
  }
  > p > span {
    padding: 0 6px;
    font-size: 14px;
    color: #fff;
  }
`;

const HeaderComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UserComp = styled.div`
  display: flex;
  cursor: pointer;
  > p > b {
    color: #ff5733;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.3px;
    text-align: center;
    margin-bottom: 0px;
    margin-right: 10px;
  }
  > p > span {
    padding: 0 6px;
    font-size: 14px;
    color: #fff;
  }
  > p > b > span {
    padding-right: 10px;
    vertical-align: baseline;
  }
`;

let theme = createTheme({});

const LayoutCover = (props) => {
  const { isAuthenticate, logout } = useAuth();
  const dispatch = useDispatch();
  // console.log(data, ">>>>> Data");
  const navigate = useNavigate();
  const [modules, setModules] = useState([]);
  const [layoutData, setlayoutData] = useState({
    collapsed: false,
  });
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  useEffect(() => {
    setlayoutData({ collapsed: !isSmUp });
  }, [isSmUp]);

  const data = useSelector((state) => state.layout.data.modulesByUserData);
  const user = useSelector((state) => state.auth.getProfile);
  const count = useSelector((state) => state.layout.data.count);
  const [openKeys, setOpenKeys] = useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (latestOpenKey == "Payment") {
      setCounts((prevCounts) => ({
        ...prevCounts,
        ["Payment"]: 0,
      }));
    } else {
      setCounts((prevCounts) => ({
        ...prevCounts,
        ["Payment"]: count.pending_count,
      }));
    }
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  useEffect(() => {
    dispatch(modulesByUserDataPanel()).then((d) => {
      setModules(d.payload);
    });
    dispatch(getStoreData());
    dispatch(getProfile());
    dispatch(getCountForLayout());
    setModules(data);
    // console.log("Count Data ", count);
  }, []);

  const { children, storeData, paymentLength } = props;
  const onCollapse = (collapsed) => setlayoutData({ collapsed });

  const [counts, setCounts] = useState({
    "/managestores/redirect-store-broken": count.broken_count,
    "/redeem/paymentstatus": count.pending_count,
    "/crm/notifications": count.notification_count,
    "/crm/alerts": count.alert_count,
    Payment: count.pending_count,
  });

  useEffect(() => {
    setCounts({
      "/managestores/redirect-store-broken": count.broken_count,
      "/redeem/paymentstatus": count.pending_count,
      "/crm/notifications": count.notification_count,
      "/crm/alerts": count.alert_count,
      Payment: count.pending_count,
    });
  }, [count]);
  // console.log("Count Data ", counts);
  const logoutHandler = async () => {
    await logout()
      .then(() => {
        message.success("Logged Out Successfully!");
        setTimeout(() => {
          window.location.reload(); //for reload of window screen to navigate to "/login" route automatically
        }, 100);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggle = () => {
    setlayoutData({
      collapsed: !layoutData.collapsed, //this is a temporary change from the current state of collapsed: !collapsed to collapsed: !layoutData.collapsed
    });
  };

  const saveStoreData = () => {
    if (props.storeData)
      localStorage.setItem("stores", JSON.stringify(props.storeData));
  };

  let href = window.location.href.split("/");
  // console.log(props.modules_by_users, "moduleByusers");

  if (href.length > 4) {
    href = href[4];
  } else {
    href = href[3];
  }
  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/profile">
          <UserOutlined />
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item onClick={logoutHandler}>
        <LogoutOutlined />
        <span>LOGOUT</span>
      </Menu.Item>
    </Menu>
  );

  const handleNotification = () => {
    // history.push("/taskreminder"); //useHistory is deprecated in react-router v6 and above
    navigate("/taskreminder");
  };

  const taskArr = [props.taskReminderData];
  const ismobile = useMediaQuery("(max-width:765px)");
  // console.log(ismobile, "ismobilde");
  const [siderCollapsedWidth, setSiderCollapsedWidth] = useState(100);
  // console.log([props.taskReminderData].length, "layout")
  useEffect(() => {
    if (ismobile) setSiderCollapsedWidth(0);
    else setSiderCollapsedWidth(80);
  }, [ismobile]);
  return (
    <div>
      <Layout
        className="mainLayout"
        style={{ position: "relative", height: "100vh" }}
      >
        <Header
          style={{
            padding: 2,

            position: "sticky",
            top: 0,
            zIndex: 200,
          }}
          className="site-layout-sub-header-background"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="hamburger"
              style={{
                marginLeft: ismobile ? "0px" : "20px",
                zIndex: 999,
                display: "flex",
                justifyContent: "start",
                gap: "20px",
                alignItems: "center",
                // width: "300px",
              }}
            >
              {layoutData.collapsed ? (
                <MenuIcon
                  sx={{ color: "#fff", fontSize: "35px", cursor: "pointer" }}
                  onClick={toggle}
                />
              ) : (
                <CloseIcon
                  onClick={toggle}
                  sx={{ color: "#fff", fontSize: "35px", cursor: "pointer" }}
                />
              )}
              <Logo>
                <p>
                  <b
                    style={{
                      fontSize: ismobile ? "4vw" : "2vw",
                      textTransform: "uppercase",
                    }}
                  >
                    {user && user.name}
                  </b>{" "}
                  {/* <span>{user && user.field}</span> */}
                </p>
              </Logo>
            </div>

            <div
              style={{
                position: "absolute",
                right: "152px",
                top: "2px",
              }}
            >
              <Badge
                count={taskArr[0] === undefined ? 0 : taskArr.length}
                size="small"
              >
                <BellFilled
                  onClick={handleNotification}
                  style={{
                    color: "#fff",
                    fontSize: "24px",
                  }}
                />
              </Badge>
            </div>
            <UserComp>
              <p onClick={logoutHandler}>
                <b>
                  <LogoutOutlined />
                  LOGOUT
                </b>
              </p>
            </UserComp>
          </Box>
        </Header>
        <Layout
          style={{
            position: "relative",

            overflowY: "hidden",
          }}
        >
          {isAuthenticate && (
            <Sider
              className="scrollContainer"
              collapsible
              collapsed={layoutData.collapsed}
              onCollapse={onCollapse}
              trigger={null}
              collapsedWidth={siderCollapsedWidth}
              width={250}
              style={{
                position: ismobile ? "fixed" : "relative",
                zIndex: "150",
                height: ismobile ? "calc(100vh - 64px)" : "",
                overflowY: "scroll",
              }}
            >
              <Menu
                theme="dark"
                defaultSelectedKeys={["/" + href]}
                selectedKeys={["/" + href]}
                mode="inline"
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                style={{}}
              >
                {data &&
                  data.length !== 0 &&
                  data.map((item) => {
                    if (!item.sub_module) {
                      return (
                        <Menu.Item key={item.module_url}>
                          <Link to={item.module_url}>
                            <DashboardOutlined />
                            {counts[item.module_name] > 0 ? (
                              <Badge count={counts[item.module_name]} showZero>
                                <span style={{ marginLeft: 8 }}>
                                  {item.module_name}
                                </span>
                              </Badge>
                            ) : (
                              <span style={{ marginLeft: 8 }}>
                                {item.module_name}
                              </span>
                            )}
                            
                          </Link>
                        </Menu.Item>
                      );
                    } else {
                      return (
                        <SubMenu
                          key={item.module_name}
                          title={
                            <span>
                              {counts[item.module_name] > 0 ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "60px",
                                  }}
                                >
                                  <CarryOutOutlined />
                                  <span>{item.module_name}</span>
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "40px",
                                      top: "-4px",
                                    }}
                                  >
                                    <Badge
                                      count={counts[item.module_name]}
                                      style={{
                                        backgroundColor: "#f50",
                                        fontSize: "10px",
                                        height: "16px",
                                        minWidth: "16px",
                                        lineHeight: "16px",
                                      }}
                                      showZero
                                    ></Badge>
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <CarryOutOutlined />
                                  <span>{item.module_name}</span>
                                </div>
                              )}
                            </span>
                          }
                          // onTitleClick={(e) =>
                          //   console.log(item.module_name, "clicked")
                          // }
                        >
                          {item.sub_module.map((subItem) => (
                            <Menu.Item key={subItem.sub_module_url}>
                              <Link to={subItem.sub_module_url}>
                                {
                                  counts[subItem.sub_module_url] > 0 ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <FileAddOutlined />
                                      <span style={{ marginLeft: "8px" }}>
                                        {subItem.sub_module_name}
                                      </span>
                                      <div
                                        style={{
                                          position: "absolute",
                                          right: "15px",
                                          top: "-2px",
                                        }}
                                      >
                                        <Badge
                                          count={counts[subItem.sub_module_url]}
                                          style={{
                                            backgroundColor: "#f50",
                                            fontSize: "10px",
                                            height: "16px",
                                            minWidth: "16px",
                                            lineHeight: "16px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <div>
                                      <FileAddOutlined />
                                      <span style={{ marginLeft: 8 }}>
                                        {subItem.sub_module_name}
                                      </span>
                                    </div>
                                  )
                                  // <Badge count={counts[subItem.sub_module_url]} >
                                  //   <span style={{ marginLeft: 8 }}>{subItem.sub_module_name}</span>
                                  // </Badge> : <span style={{ marginLeft: 8 }}>{subItem.sub_module_name}</span>
                                }
                              </Link>
                            </Menu.Item>
                          ))}
                        </SubMenu>
                      );
                    }
                  })}
                <Menu.Item onClick={logoutHandler}>
                  <LogoutOutlined />
                  <span>LOGOUT</span>
                </Menu.Item>
              </Menu>
            </Sider>
          )}
          <Content
            className="scrollContainer"
            style={{
              margin: "0 16px",
              overflowY: "scroll",
              height: "100%",
            }}
          >
            <div
              className="site-layout-background"
              style={{ padding: 24, background: "#fff", minHeight: 360 }}
            >
              {children}
            </div>
          </Content>
        </Layout>

        {/* <Footer
          style={{ background: "#001529", color: "white", textAlign: "center",height:"20px" }}
        >
          Hyyzo Admin Panel
        </Footer> */}
      </Layout>
    </div>
  );
};

export default LayoutCover;
